<script>
  import { defineComponent } from "@vue/composition-api";

  export default defineComponent({
    setup() {}
  });
</script>

<template>
  <h2>Project Edit</h2>
</template>
